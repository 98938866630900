import React, {useContext, useState} from 'react'
import {MyContext} from './MyContext';

import axios from 'axios';

import Logo from '../../components/assets/login/logo.jpg';

import '../../App.css';

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';


function Login(){

    const {toggleNav,loginUser,isLoggedIn} = useContext(MyContext);

    const [modalEliminar, setModalEliminar]=useState(false);

    const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

    const initialState = {
        userInfo:{
          //  email:'',
            documento:'',
            clave:'',
        },
        errorMsg:'',
        successMsg:'',
    }

    const [state,setState] = useState(initialState);

    //insertar 

    const baseUrlAd="https://gemcontsoft.hoztcrea.com/apiAlira/empresas_usuarios/demo.php"
    const [data, setData]=useState([]);


    const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
        correo:'',
        celular:'',
        nombre:'',
        documento:'',
      });

    const handleChange=e=>{
        const {name, value}=e.target;
        setFrameworkSeleccionado((prevState)=>({
          ...prevState,
          [name]: value
        }))
       console.log(frameworkSeleccionado);
      }

      const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
      }


      const peticionPost = async() =>{
        var f = new FormData();
        f.append("correo", frameworkSeleccionado.correo);
        f.append("celular", frameworkSeleccionado.celular);
        f.append("nombre", frameworkSeleccionado.nombre);
        f.append("nit", frameworkSeleccionado.nit);  
        f.append("METHOD", "POST");
        await axios.post(baseUrlAd,f)
        .then(response=>{
         setData(data.concat(response.data));
        
         abrirCerrarModalEliminar();
        }).catch(error=>{
          console.log(error);
        })
    
      }


      

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    const [recuperar_pass, setRecuperar_pass] = useState("VOLVER A INICIO");

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }

    const recuperar_password= (e) => {
        // console.log(e.target.value) // si || no
        setRecuperar_pass(e.target.value)
      }

    

    return(
<>
<div id="loader" className="app-loader">
</div>


<div id="app" className="app">

<div className="login login-with-news-feed">

<div className="news-feed">
<div className="news-image Fondo" ></div>

</div>


<div className="login-container">

<div className="login-header mb-90px">
<div className="brand">
<div className="align-items-center">
<center><img src={Logo} width="250px" /></center>
<br/>
</div>

</div>
{/* <div className="icon">
<i className="fa fa-sign-in-alt"></i>
    </div> */}
</div>


<div className="login-content">
{recuperar_pass === "VOLVER A INICIO" && (
<form className="fs-13px" onSubmit={submitForm} noValidate>
<div className="form-floating mb-15px">
<input name="documento" type="text" className="form-control h-45px fs-13px" placeholder="Usuario"
 value={state.userInfo.documento} onChange={onChangeValue} autoComplete="off" />
<label type="number" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Documento</label>
</div>
<div className="form-floating mb-15px">
<input name="clave" type="password" className="form-control h-45px fs-13px" placeholder="Password" autoComplete="off"
 value={state.userInfo.clave} onChange={onChangeValue} />
<label for="password" className="d-flex align-items-center fs-13px text-gray-600">Contraseña</label>
</div>


<div className="mb-15px ">
<button type="submit" className="btn btn-info d-block h-45px w-100 btn-lg fs-14px">INICIAR SESIÓN</button>
</div>

<div className='row'>

<div className="mb-5px col-6 ">
<input type="button" value="RECUPERAR CLAVE"  className="btn btn-primary d-block h-35px w-100 btn-lg fs-12px"  onClick={recuperar_password} />
</div>

<div className="mb-5px  col-6">
<input type="button" value="SOLICITAR DEMO" className="btn btn-primary d-block h-35px w-100 btn-lg fs-12px" onClick={recuperar_password}  /> 
</div>
</div>

{/*
<div className="mb-40px pb-40px text-dark">
 <a href="#" className="text-primary" style={{textDecoration: 'none'}}>SOLICITAR <b>DEMO</b></a>
</div>
    */}
<div className="mb-40px pb-40px text-dark">
</div>

<center><h4>{errorMsg}</h4></center>
{successMsg}
<hr className="bg-gray-600 opacity-2" />
<div className="text-gray-600 text-center  mb-0">
&copy; Todos los derechos reservados gemcontsoft.com
</div>
</form>

)}

<div className="col-sm-12">
        {recuperar_pass === "RECUPERAR CLAVE" && (
            <>
            <form className="fs-13px" method="post" action="https://gemcontsoft.hoztcrea.com/email/clave_usuarios.php" target="_parent" name="reset" noValidate>
          <div className="form-floating mb-15px">
          <input name="email" type="email" className="form-control h-45px fs-13px" placeholder="Usuario"
            autoComplete="off" />
          <label type="email" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email</label>
          </div>
         
          <div className="mb-15px">
          <button type="submit" onClick={loadData} disabled={loading} className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">RECUPERAR CLAVE</button>
          </div>
        

          <div className="mb-40px pb-40px text-dark">
 
 <input
            type="button"
           
            value="VOLVER A INICIO"    
            className="btn btn-default"       
            onClick={recuperar_password}
          /> 
</div> 
          
          
<hr className="bg-gray-600 opacity-2" />
<div className="text-gray-600 text-center  mb-0">
&copy; Todos los derechos reservados gemcontsoft.com
</div>
          </form>
          </>
         
        )}
      </div>


      <div className="col-sm-12">
        {recuperar_pass === "SOLICITAR DEMO" && (
            <>

<div className="form-group">
            
          <div className="form-floating mb-15px">
          <input name="correo" type="email" className="form-control h-45px fs-13px" placeholder="Correo"
            autoComplete="off" onChange={handleChange}  required />
          <label type="email" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email</label>
          </div>

          <div className="form-floating mb-15px">
          <input name="celular" type="number" className="form-control h-45px fs-13px" placeholder="Teléfono"
            autoComplete="off" onChange={handleChange} required />
          <label type="text" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Teléfono</label>
          </div>

          <div className="form-floating mb-15px">
          <input name="nombre" type="text" className="form-control h-45px fs-13px" placeholder="Nombre / Empresa"
            autoComplete="off" onChange={handleChange} required />
          <label type="text" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Nombre / Empresa</label>
          </div>

          <div className="form-floating mb-15px">
          <input name="nit" type="number" className="form-control h-45px fs-13px" placeholder="Documento"
            autoComplete="off" onChange={handleChange} required />
          <label type="text" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Documento / NIT</label>
          </div>
         
         
          <div className="mb-15px">
          <button onClick={()=>peticionPost()} disabled={loading} className="btn btn-success d-block h-45px w-100 btn-lg fs-14px">SOLICITAR DEMO</button>
          </div>
        

          <div className="mb-40px pb-40px text-dark">
 
 <input
            type="button"
           
            value="VOLVER A INICIO"    
            className="btn btn-default"       
            onClick={recuperar_password}
          /> 
</div> 
          
          
<hr className="bg-gray-600 opacity-2" />
<div className="text-gray-600 text-center  mb-0">
&copy; Todos los derechos reservados gemcontsoft.com
</div>

</div>

<Modal isOpen={modalEliminar}>
 <ModalBody>
   SOLICITUD DEMO GEMERADA CORRECTAMENTE PARA - {frameworkSeleccionado && frameworkSeleccionado.nombre}
   </ModalBody>
   <ModalFooter>

   

   <input
            type="button"
           
            value="VOLVER A INICIO"    
            className="btn btn-default"       
            onClick={recuperar_password}
            //onClick={()=>recuperar_password()}
          /> 
    
    {/* <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
        ACEPTAR
        </button> */}

   </ModalFooter>
 

</Modal>

          </>
          
        )}
      </div>

</div>

</div>

</div>

</div>



<a href="javascript:;" className="btn btn-icon btn-circle btn-success btn-scroll-to-top" data-toggle="scroll-to-top"><i class="fa fa-angle-up"></i></a>


</>
      
    );
}

export default Login;