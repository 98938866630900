import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

const Count = () => {


    const [tipo_usuario, setData]=useState([]);

    const peticionGet =async() =>{
      await axios.get(`https://gemcontsoft.hoztcrea.com/apiAlira/empresas/count_empresas.php`)
      .then(res=>{
       setData(res.data);
      }).catch(error=>{
        console.log(error);
      })
  
    }


    const [tipo_usuario_s, setData_s]=useState([]);

    const peticionGet_e_suspendidas =async() =>{
        await axios.get(`https://gemcontsoft.hoztcrea.com/apiAlira/empresas/count_empresas_suspendias.php`)
        .then(res=>{
         setData_s(res.data);
        }).catch(error=>{
          console.log(error);
        })
    
      }


      const [suspendido, setData_suspendido]=useState([]);

    const peticionGet_licencias =async() =>{
        await axios.get(`https://gemcontsoft.hoztcrea.com/apiAlira/empresas/count_licencias_empresas.php`)
        .then(res=>{
         setData_suspendido(res.data);
        }).catch(error=>{
          console.log(error);
        })
    
      }


      
      const [nombre, setData_nombre]=useState([]);

    const peticionGet_solicitudes =async() =>{
        await axios.get(`https://gemcontsoft.hoztcrea.com/apiAlira/empresas/count_solicitudes_empresas.php`)
        .then(res=>{
          setData_nombre(res.data);
        }).catch(error=>{
          console.log(error);
        })
    
      }

    

 useEffect(()=>{
    

      const interval = setInterval(() => {
        
        peticionGet();
        peticionGet_e_suspendidas();
        peticionGet_licencias();
        peticionGet_solicitudes();
         
       }, 1000);
    
     return () => setInterval(interval);
    
     },[])

  return (
    <div class="row">


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-orange">
<div class="stats-icon"><i class="fa fa-users"></i></div>
<div class="stats-info">
<h4>EMPRESAS CREADAS</h4>
<p>{tipo_usuario}</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_creadas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>

<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-blue">
<div class="stats-icon"><i class="fa fa-desktop"></i></div>
<div class="stats-info">
<h4>LICENCIAS</h4>
<p>{suspendido}</p>
</div>
<div class="stats-link">
<Link  to="/Licencias" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-info">
<div class="stats-icon"><i class="fa fa-link"></i></div>
<div class="stats-info">
<h4>SOLICITUDES DEMO</h4>
<p>{nombre}</p>
</div>
<div class="stats-link">
<Link  to="/Solicitudes" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>EMPRESAS SUSPENDIDAS</h4>
<p>{tipo_usuario_s}</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>

</div>



  )
}

export default Count