import React, {useContext,useState,useEffect,useRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


//import { AddBox, ArrowDownward } from "@material-ui/icons";
import MaterialTable from "material-table";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SignatureCanvas from 'react-signature-canvas'


/**** camara  ***/
import Webcam from "react-webcam";
 
const videoConstraints = {
  width: 540,
  facingMode: "environment",
};
 


/*import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css'*/


//import Row from "./Row";

const multiregistros = {
  documento: "",
  name: "",
  cargo: "",
  clave: "",
  id_empresa_usuario: "",
  nombre_empresa: "",
  documento_empresa: ""
};




function F_electronica () {

  //camara

  const webcamRef = useRef(null);
  const [url, setUrl] = React.useState(null);

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
  }, [webcamRef]);
 
  const onUserMedia = (e) => {
    console.log(e);
  };




  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

  const sigCanvas = useRef({})

  const limpiar_firma = () => sigCanvas.current.clear()
  const [imageURL,setImageURL] = useState(null)
  const guardar_firma = () => setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))

  const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }


    



 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://gemcontsoft.hoztcrea.com/apiAlira/empresas_usuarios/empleados.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    documento:'',
    name:'',
    cargo:'',
    clave:'',
    id_empresa_usuario:'',
    nombre_empresa:'',
    documento_empresa:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(`https://gemcontsoft.hoztcrea.com/apiAlira/electronica/factura.php`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }


   const peticionPut = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "PUT");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.name= frameworkSeleccionado.name;
         framework.documento= frameworkSeleccionado.documento;
         framework.cargo= frameworkSeleccionado.cargo;
        framework.clave = frameworkSeleccionado.clave;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }



  const peticionPost = async() =>{
    var f = new FormData();
    f.append("documento", frameworkSeleccionado.documento);
    f.append("name", frameworkSeleccionado.name);
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("id_empresa_usuario", theUser.id);
    f.append("nombre_empresa", theUser.name);
    f.append("documento_empresa", theUser.documento);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "PUT_suspender");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })


  }


  const [cargo, setCountry]= useState([]);

  useEffect( ()=>{

    const interval = setInterval(() => {
    const getcountry= async ()=>{
   const req= await fetch(`https://gemcontsoft.hoztcrea.com/apiAlira/empresas_usuarios/cargar_cargos.php?usuarioE=${global.idprincipal}`)
  // const req= await fetch("https://parqueoenvia.co/apiParqueoenvia/zonas/country.php");
      const getres= await req.json();
      console.log(getres);
      setCountry(await getres);
  
    }
    getcountry();

}, 2000);

return () => setInterval(interval);
  
  
   },[]);

  

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"EMPRESA - PERSONA",
      field: "nombre"
    },

    {
        title:"DOCUMENTO",
        field: "nit"
      },

    {
      title:'FECHA GENERACIÓN',
      field:'fecha'
    },
      
      {
        title:'VALOR',
        field:'valor'
      },

      
      {
        title:'FACTURA',
        field:'factura',
       
       // render: rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}/>   
    },
    
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="empleado")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">




{/*<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>PUNTOS SUSPENDIDOS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
  </div> */}

</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">{theUser.nombre_empresa} - {theUser.cargo} </h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><i class="fas fa-user fa-2x"></i> </> }
backIconButtonText='Página anterior'
pagination
fixedHeader


actions={[

    {
        icon : 'attachment',
        tooltip: 'Hoja de vida',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
 
     
            /*  {
                  icon: 'block',
                  tooltip: 'suspender',
                  onClick: (event, framework) => seleccionarFramework(framework, "Eliminar")
              },
*/
             
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar'
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea inhabilitar al usuario {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar} style={{maxWidth:"800px"}} >
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">FACTURA ELECTRÓNICA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

  

<iframe src={"https://docs.google.com/gview?url=https://www.gemcontsoft.hoztcrea.com/apiAlira/facturas/Fac1.pdf&embedded=true"}  frameborder="0" style={{width:"100%", height:"400px"}} ></iframe>

   <div class="col-7">
    <br/>

<label>Codificación:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
      value="Elaborado por software siigo nube y enviado electronicamente por proveedor tecnologico"  readOnly />
  </div>

  <div class="col-5">
  <br/>
  <label>Fecha de generación</label>
  <br/>
  <input type="text" className="form-control" name="documento" onChange={handleChange}  
  value="02-02-2023" readOnly/>
  
  </div>
  
  
  
            <div class="col-7">
            <br/>
  <label>Empresa</label>
  <br/>
  <input type="text" className="form-control" name="cargo" onChange={handleChange}  
  value="HOSTCREATIVE SAS" readOnly/>
  
  </div>


  <div class="col-5">
            <br/>
  <label>Documento</label>
  <br/>
  <input type="text" className="form-control" name="cargo" onChange={handleChange}  
  value="900772610-1" readOnly/>
  
  </div>

  <div class="col-12">
<br/>
<label>Observaciones:</label>
<br/>
      <textarea type="text" rows={4} className="form-control"  name="observaciones" onChange={handleChange}
       value="A esta factura de venta aplican las normas relativas a la letra de cambio (artículo 5 Ley 1231 de 2008). Con esta el Comprador declara haber recibido real y materialmente las mercancías o prestación de servicios descritos en este título - Valor. Número Autorización 18764041030883 aprobado en 20221209 prefijo desde el número 49 al 168 Vigencia: 6 Meses" readOnly
        />
  </div>



   </div>
   </div>
 </ModalBody>
 <ModalFooter>
  {/* <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "} */}
   <button className="btn btn-primary w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Aceptar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">USUARIO</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-7">

<label>Nombre completos:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
        />
  </div>

<div class="col-5">

<label>Documento:</label>
<br/>
      <input type="text" className="form-control"  name="documento" onChange={handleChange}
        />
  </div>

  <div class="col-6">
  <br/>
  <label>Contraseña:</label>
  <br/>
  <input type="text"  className="form-control" required minLength={8} maxLength={15}  name="clave" onChange={handleChange} 
   />
   <label>Mínimo 8 caracteres</label>
  <br/>
  </div>


  
  <div className="col-6">
    
   <br/>
                 <label>Agregar cargo:</label>
              <select name="cargo" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar cargo--</option>
                   {
                     cargo.map( (getcon)=>(
                      
                   <option key={getcon.id} value={getcon.cargo}> {getcon.cargo}</option>
                
                     ))
                    
                }

                              
                 </select>
               
            </div> 
  
  <div style={{display:"none"}}>
  <input name="id_empresa_usuario"  className="form-control" type="text" placeholder="Id empresa" value={theUser.id} autoComplete="off" readOnly />
  <input name="nombre_empresa"  className="form-control" type="text" placeholder="Nombre emperesa" value={theUser.name} autoComplete="off" readOnly />
  <input name="documento_empresa"  className="form-control" type="text" placeholder="Documento emperesa" value={theUser.documento} autoComplete="off" readOnly />
  

  
  </div>
  
 

  </div>

   
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default F_electronica
